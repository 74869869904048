import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { clearErrors, setSelectedPromoCode } from '../../../actions/loyalty';
import brandEnum from '../../../enums/brandEnum';
import countryCodeEnum from '../../../enums/countryCodeEnum';
import * as selectors from '../../../sagas/selectors';

import Button from '../../Button';
import EnvQueryComponent from '../../EnvQueryComponent';
import Input from '../../Input';
import SectionSeparator from '../../SectionSeparator';

import dbsLogo from '../../../images/dbs-logo.png';

import './PromoCodeInput.css';

function PromoCodeInput() {
  const [promoCode, setPromoCode] = useState('');
  const [inputError, setInputError] = useState('');

  const promoCodeError = useSelector((state) => selectors.getLoyaltyError(state, 'promoCode'));
  const selectedPromoCode = useSelector(selectors.getSelectedPromoCode);
  const selectedUserRewardUuid = useSelector(selectors.getSelectedUserRewardUuid);
  const isClimatePositiveHidden = useSelector(selectors.getIsClimatePositiveHidden);

  const errorMessage = useMemo(
    () => inputError || promoCodeError?.message,
    [inputError, promoCodeError],
  );

  const dispatch = useDispatch();

  const onChange = (event) => {
    if (promoCodeError) {
      dispatch(clearErrors());
    }
    setPromoCode(event.target.value);
    setInputError('');
  };
  const onApply = () => {
    if (!promoCode) {
      setInputError('Please enter a promo code');
      return;
    }
    dispatch(setSelectedPromoCode({ promoCode }));
  };
  const onClear = () => {
    dispatch(clearErrors());
    setPromoCode('');
    setInputError('');
  };

  // Don't show promo code input if promo code/reward is selected
  if ((selectedPromoCode && !promoCodeError) || selectedUserRewardUuid) return null;

  return (
    <div className="PromoCodeInputWrapper">
      <SectionSeparator typographyVariant="h5">PROMO CODE</SectionSeparator>
      <div className={classnames('PromoCodeInputMain', { hasError: errorMessage })}>
        <div className="PromoCodeInput">
          <Input
            className={classnames({ hasError: errorMessage, noValue: !promoCode })}
            type="text"
            placeholder="Enter code"
            value={promoCode}
            onChange={onChange}
          />
          {promoCode && <Button className="ClearButton" fa="circle-xmark" onClick={onClear} />}
        </div>
        <Button className="btn-primary" onClick={onApply}>
          Apply
        </Button>
        {!isClimatePositiveHidden && (
          <EnvQueryComponent
            countryCode={[countryCodeEnum.SINGAPORE]}
            brand={[brandEnum.SALADSTOP]}
            showOnMobile={false}
          >
            <div className="DbsLogoWrapper">
              <img src={dbsLogo} alt="dbs logo" className="DbsLogo" />
            </div>
          </EnvQueryComponent>
        )}
      </div>
      {errorMessage && <div className="PromoCodeError">{errorMessage}</div>}
    </div>
  );
}

export default PromoCodeInput;
