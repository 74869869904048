import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import validator from 'email-validator';

import * as userActions from '../../../actions/user';
import * as selectors from '../../../sagas/selectors';
import { getSearchParam } from '../../../utils/historyUtils';

import FacebookLogin from '../../../containers/FacebookLogin';
import Button from '../../Button';
import EmailAddress from '../../EmailAddress';
import InputAlertText from '../../InputAlertText';

import { ReactComponent as FreshIDLogo } from './freshid.svg';
import { ReactComponent as SaladStopLogo } from './saladstop-logo.svg';
import { ReactComponent as WooshiLogo } from './wooshi-logo.svg';

import './SignInRouter.css';

function SignInRouter() {
  const { search } = useSelector(selectors.getCurrentLocation);
  const { flow } = useSelector(selectors.getCurrentLocationHistory);
  const loginMethod = getSearchParam(search, 'method') || null;
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [email, setEmail] = useState(useSelector(selectors.getRegistrationEmail));
  const dispatch = useDispatch();

  const isKioskPairingFlow = useMemo(() => flow === 'kioskPairingFlow', [flow]);
  const isScanOrderNumberFlow = useMemo(() => flow === 'scanOrderNumberFlow', [flow]);
  const validateEmail = () => validator.validate(email);

  const handleEmailChange = (event) => setEmail(event.target.value.toLowerCase());

  const handleEmailSubmit = (event) => {
    event.preventDefault();

    const isValid = validateEmail();
    setIsEmailValid(isValid);

    if (isValid) {
      dispatch(userActions.setRegistrationEmail({ email }));
      dispatch(userActions.signInDispatch());
    }
  };

  return (
    <form onSubmit={handleEmailSubmit}>
      <div className="padded-bottom">
        {isKioskPairingFlow ? (
          <Typography variant="h3" color="secondary.light">
            Please login to redeem reward
          </Typography>
        ) : (
          <Typography variant="h1" color="secondary.light" className="SignInTitle">
            LOG IN
          </Typography>
        )}
      </div>
      {isScanOrderNumberFlow && (
        <Typography variant="subtitle2" className="padded-bottom-large" color="grey.main">
          Sign up or log in to credit your purchase.
        </Typography>
      )}
      <div className="SignInLogos padded-bottom-large">
        <FreshIDLogo /> <SaladStopLogo /> <WooshiLogo />
      </div>
      <div>
        {loginMethod === 'FACEBOOK' && (
          <div>
            <InputAlertText className="lower" noIcon>
              Couldn’t login using email.
            </InputAlertText>
            <p>
              It looks like the email you entered was associated with a Facebook &nbsp;account on
              our website, please use this button to login.
            </p>
          </div>
        )}
        {loginMethod === 'PASSWORD' && (
          <div>
            <InputAlertText className="lower" noIcon>
              Couldn’t login using Facebook.
            </InputAlertText>
            <p>
              It looks like the Facebook account you used is associated with &nbsp;an email account
              on our website, please login using email.
            </p>
          </div>
        )}
        {(loginMethod === 'PASSWORD' || loginMethod === null) && (
          <div>
            <Typography variant="subtitle1" color="secondary.light" className="EmailLabel">
              Email
            </Typography>
            <EmailAddress
              className="form-register"
              type="text"
              placeholder="hello@heybo.sg"
              value={email}
              onChange={handleEmailChange}
            />
            {isEmailValid === false && (
              <InputAlertText noIcon>Invalid email address</InputAlertText>
            )}
            <Button className="btn-primary" disabled={email?.length < 1}>
              Sign up or log in
            </Button>
          </div>
        )}
        {loginMethod === null && <div className="padded-top padded-bottom-large">or</div>}
        {(loginMethod === 'FACEBOOK' || loginMethod === null) && (
          <div>
            <FacebookLogin />
          </div>
        )}
      </div>
    </form>
  );
}

export default SignInRouter;
