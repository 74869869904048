import React from 'react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../Button';
import InputAlertText from '../../InputAlertText';

import './Phone.css';

function Phone(props) {
  const {
    countryCode,
    phone,
    error,
    isCountryCodeValid,
    isPhoneValid,
    onCountryCodeChange,
    onPhoneChange,
    onFormSubmit,
  } = props;

  return (
    <form onSubmit={onFormSubmit}>
      <Typography
        variant="h1"
        color="secondary.light"
        className="SignInTitle visible-lg visible-md"
        fontWeight="900"
      >
        Your First Number
      </Typography>
      {!error && (
        <p className="SignInCopy">
          Thank you for your registration, please key in your phone number
        </p>
      )}
      {error && <InputAlertText noIcon>{error}</InputAlertText>}
      <div className="padded-bottom-large">
        <ul className="phone-no">
          <li>
            <div className={classnames({ 'input-alert': !isCountryCodeValid })}>
              <input
                className="payment-info phone-number"
                type="text"
                placeholder="+65 Singapore"
                value={countryCode}
                onChange={onCountryCodeChange}
              />
            </div>
          </li>
          <li>
            <div className={classnames({ 'input-alert': !isPhoneValid })}>
              <input
                className="payment-info phone-number"
                type="tel"
                placeholder="Contact number"
                value={phone}
                onChange={onPhoneChange}
              />
            </div>
          </li>
        </ul>
      </div>
      <Button className="btn-primary">Submit</Button>
    </form>
  );
}

Phone.propTypes = {
  countryCode: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  error: PropTypes.string,
  isCountryCodeValid: PropTypes.bool.isRequired,
  isPhoneValid: PropTypes.bool.isRequired,
  onCountryCodeChange: PropTypes.func.isRequired,
  onPhoneChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

Phone.defaultProps = {
  error: null,
};

export default Phone;
