import React from 'react';
import PropTypes from 'prop-types';

import paymentMethodShape from '../../shapes/paymentMethodShape';

import FontAwesome from '../FontAwesome';
import OrderPaymentLine from '../OrderPaymentLine';
import SectionSeparator from '../SectionSeparator';

import './OrderPayment.css';

function OrderPayment(props) {
  const {
    subtotal,
    surchargeInfo,
    totalPrice,
    discountTitle,
    discountAmount,
    salesTax,
    paymentMethod,
  } = props;

  const showSubtotal = surchargeInfo.length > 0 || discountAmount > 0;

  return (
    <div className="OrderPayment">
      <div className="OrderPaymentTitle">
        <SectionSeparator>Payment</SectionSeparator>
        {paymentMethod && (
          <div className="paymentMethodLabel">
            <FontAwesome name="credit-card" />
            &ensp;
            {paymentMethod.cardBrand}
            &ensp;
            {paymentMethod.label}
          </div>
        )}
      </div>
      <div className="surcharges">
        {showSubtotal && <OrderPaymentLine amount={subtotal}>Subtotal</OrderPaymentLine>}
        {surchargeInfo.map((surcharge) => (
          <OrderPaymentLine key={surcharge.surchargeType} amount={surcharge.price}>
            {surcharge.description}
          </OrderPaymentLine>
        ))}
      </div>
      {discountAmount > 0 && (
        <div className="discounts">
          <OrderPaymentLine amount={discountAmount} negative>
            {discountTitle}
          </OrderPaymentLine>
        </div>
      )}
      <OrderPaymentLine amount={totalPrice} tax={salesTax} typographyVariant="subtitle1" total>
        Total
      </OrderPaymentLine>
    </div>
  );
}

OrderPayment.propTypes = {
  subtotal: PropTypes.number.isRequired,
  surchargeInfo: PropTypes.arrayOf(PropTypes.object),
  totalPrice: PropTypes.number.isRequired,
  discountTitle: PropTypes.string,
  discountAmount: PropTypes.number.isRequired,
  salesTax: PropTypes.number.isRequired,
  paymentMethod: paymentMethodShape,
};

OrderPayment.defaultProps = {
  surchargeInfo: [],
  discountTitle: null,
  paymentMethod: null,
};

export default OrderPayment;
